<template>
	<div class="questionnaire-page">
		<!-- 		<div class="search-module mb20">
			<a-input-search v-model="searchInfo" placeholder="请输入姓名/手机号" enter-button="搜索" size="large" :loading="searchIng" @search="onSearch" />
		</div> -->
		<div class="control-box flex flex-end mb20"><a-button size="small" type="primary" @click="toAdd">新增问卷</a-button>
		</div>
		<div class="table-box">
			<a-table :rowKey="
				(item, index) => {
					return index;
				}
			" :loading="dataLoading" :columns="columns" :data-source="list"
				:pagination="{ hideOnSinglePage: true, current: page.current, total: page.total }" @change="listChange"
				:bordered="true">
				customRender: (text, record, index) => `${record.count_read_use}/${record.count}`

				<template slot="count_read_use" slot-scope="index, item">
					<a-button size="small" type="link" @click="showUserModal(1, item.wenjuan_id)">{{ item.count_read_use
							+ '/' + item.count
					}}</a-button>
				</template>
				<template slot="count_read_un_use" slot-scope="index, item">
					<a-button size="small" type="link" @click="showUserModal(2, item.wenjuan_id)">{{
							item.count_read_un_use + '/' + item.count
					}}</a-button>
				</template>
				<template slot="count_read_un_allow" slot-scope="index, item">
					<a-button size="small" type="link" @click="showUserModal(4, item.wenjuan_id)">{{
							item.count_read_un_allow + '/' + item.count
					}}</a-button>
				</template>
				<template slot="status" slot-scope="index, item">
					<div class="control-box">
						<span class="span-btn" :style="{ color: item.is_use == 1 ? '#1890ff' : '#333' }">{{ item.is_use
								== 1 ? '已启用' : '未启用'
						}}</span>
						|
						<span class="span-btn" @click="isDisabled(item)" size="small"
							:style="{ color: item.is_use == 1 ? '#ff4d4f' : '#1890ff' }"
							:type="item.is_use == 1 ? 'danger' : 'primary'">{{ item.is_use == 1 ? '禁用' : '启用' }}</span>
					</div>
				</template>
				<template slot="operation" slot-scope="index, item">
					<div class="control-box">
						<a-button size="small" type="primary" @click="showSendModal(item)">
							发送
						</a-button>
						<a-button size="small" type="primary" :disabled="item.count > 0"
							@click="toEdit(item.wenjuan_id)">编辑</a-button>
						<a-button size="small" type="primary" @click="toView(item.wenjuan_id)">查看</a-button>
						<a-popconfirm title="确认复制该问卷" ok-text="是" cancel-text="否"
							@confirm="copyWenjuan(item.wenjuan_id)">
							<a-button size="small" type="primary">复制</a-button>
						</a-popconfirm>
						<a-popconfirm :disabled="item.count_read_allow <= 0" title="确认下载该问卷分析" ok-text="是"
							cancel-text="否" @confirm="downloadFenxi(item.wenjuan_id)">
							<a-button size="small" :disabled="item.count_read_allow <= 0" style="margin-right: 10px;"
								type="primary">分析下载</a-button>
						</a-popconfirm>
						<a-popconfirm title="确认删除该问卷" ok-text="是" cancel-text="否"
							@confirm="deleteWenjuan(item.wenjuan_id)">
							<a-button :disabled="item.count > 0" size="small" type="primary" :loading="deling"><a-icon
									style="font-size: 20px;" type="delete" /></a-button>
						</a-popconfirm>
					</div>
				</template>
			</a-table>
		</div>

		<a-modal v-model="userModalShow"
			:title="(userParams.type == 1 ? '已读' : userParams.type == 2 ? '未读' : '拒绝') + '用户'" :cancelText="'取消'"
			:okText="'全部导出'" @ok="exportExcel">
			<a-table :rowKey="
				(item, index) => {
					return index;
				}
			" :loading="userLoading" :columns="userColumns" :data-source="userList"
				:pagination="{ hideOnSinglePage: true, current: page.current, total: page.total }"
				@change="userlistChange" :bordered="true"></a-table>
		</a-modal>

		<a-modal v-model="sendShow" title="操作" :confirmLoading="sendIng" :cancelText="'取消'" :okText="'提交'"
			@ok="sendMsg">
			<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="公告标题:" prop="is_wd">
				<div>{{ sendTitle }}</div>
			</a-form-model-item>
			<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="发送方式:" prop="is_wd">
				<a-radio-group :options="sendOption" @change="typeChange" v-model="sendParam.type" />
			</a-form-model-item>
			<template v-if="sendParam.type != 1">
				<a-form-model-item v-if="sendParam.type == 2" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
					label="发送对象:" prop="is_wd">
					<a-select show-search :value="sendParam.customer_id" placeholder="姓名" style="width: 100%"
						:default-active-first-option="false" :filter-option="false"
						:not-found-content="fetching ? undefined : null" @search="findUser" @change="setUser">
						<a-spin v-if="fetching" slot="notFoundContent" size="small" />
						<a-select-option :value="item.customer_id" v-for="(item, index) in seachUserData"
							:key="index">{{ item.name }}</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item v-if="sendParam.type == 3" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
					label="批量发送:" prop="is_wd">
					<!-- <a-input-search placeholder="文件名称" enter-button @search="findUser" /> -->
					<a-button @click="chooseFile">
						<a-icon type="upload" />
						{{ sendParam.file.name ? sendParam.file.name : '选择文件' }}

					</a-button>
					<a-button @click="downloadUserMb" v-if="sendParam.type == 3" type="link">下载模板</a-button>
				</a-form-model-item>

			</template>
			<a-form-model-item :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }" label="短信通知:">
				<a-switch v-model="sendParam.is_message"></a-switch>

				<div id="" style="line-height: 1.6">
					本消息将通过公众号消息同步推送。<br>
					短信同步推送【可选】(短信服务费由第三方收取，请酌情发送)
				</div>
			</a-form-model-item>
		</a-modal>

		<input type="file" name="" id="fileEle" value="" style="opacity: 0;position: fixed;pointer-events: none;" />
	</div>
</template>

<script>
import { pro_api } from '@/siteInfo.js';
let columns = [
	{
		title: '问卷ID',
		key: 'id',
		dataIndex: 'id'
	},
	{
		title: '问卷名称',
		key: 'title',
		dataIndex: 'title'
	},
	{
		title: '答卷',
		key: 'count_read_allow',
		dataIndex: 'count_read_allow'
	},
	{
		title: '已读/总人数',
		width: '108px',
		scopedSlots: { customRender: 'count_read_use' }
	},
	{
		title: '未读/总人数',
		width: '108px',
		scopedSlots: { customRender: 'count_read_un_use' }
	},
	{
		title: '拒绝人数',
		width: '106px',
		scopedSlots: { customRender: 'count_read_un_allow' }
	},
	{
		title: '创建时间',
		key: 'create_at_str',
		dataIndex: 'create_at_str'
	},
	{
		title: '状态',
		width: '115px',
		scopedSlots: { customRender: 'status' }
	},
	{
		title: '操作',
		dataIndex: 'operation',
		width: '372px',
		scopedSlots: { customRender: 'operation' }
	}
];

let userColumns = [
	{
		title: 'ID',
		dataIndex: 'id',
		key: 'id'
	},
	{
		title: '姓名',
		dataIndex: 'name',
		key: 'name'
	},
	{
		title: '手机号',
		dataIndex: 'phone',
		key: 'phone'
	},
	{
		title: '户籍地',
		customRender: (text, item, index) =>
			`${item.domicile_pro + item.domicile_city + item.domicile_county + item.domicile_street + item.domicile_community + item.domicile_address}`
	}
];
let sendOption = [
	{
		label: '全部用户',
		value: 1
	},
	{
		label: '指定发送',
		value: 2
	},
	{
		label: '批量发送',
		value: 3
	}
];
export default {
	data() {
		return {
			searchInfo: '',
			list: [],
			columns: columns,
			userColumns: userColumns,
			dataLoading: false,
			searchIng: false,
			page: {
				current: 1,
				total: 0
			},
			deling: false,
			userModalShow: false,
			userLoading: false,
			userParams: {
				type: null,
				wenjuan_id: null
			},
			userList: [],
			sendShow: false,
			sendOption: sendOption,
			sendIng: false,
			sendTitle: '',
			fileEle: null,
			fetching: false,
			seachUserData: [],
			sendParam: {
				type: 4,
				customer_id: '',
				is_message: false,
				file:{}
			}
		};
	},
	computed: {
		token() {
			return this.$store.state.user.token
		}
	},
	created() { },
	mounted() {
		let t = this
		this.fileEle = document.getElementById('fileEle')
		this.fileEle.addEventListener('change', function (e) {
			console.log(e.target.files[0])
			console.log('gaibianle',t.sendParam)
			t.sendParam.file = e.target.files[0]
			console.log('sendParam',t.sendParam)
		})
		this.getData();
		this.findUser('')
	},
	methods: {
		toAdd() {
			this.$router.push('/questionnaire/addQuestionnaire');
		},
		getData() {
			let t = this;
			t.dataLoading = true;
			t.$get({
				port: 'a',
				url: 'wenjuanList',
				data: {
					page: t.page.current,
					searchInfo: t.searchInfo,
					bm_tz: 2
				}
			})
				.then(res => {
					t.dataLoading = false;
					t.searchIng = false;
					let { code, data, msg } = res;
					if (code == 0) {
						t.list = data.list;
						t.page.total = data.total;
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {
					t.dataLoading = false;
					t.searchIng = false;
				});
		},
		listChange(e) {
			this.page.current = e.current;
			this.getData();
		},
		onSearch() {
			let t = this;
			t.searchIng = true;
			t.page.current = 1;
			t.getData();
		},
		toEdit(id) {
			this.$router.push({ path: '/questionnaire/addQuestionnaire', query: { wenjuan_id: id } });
		},
		toView(id) {
			this.$router.push({ path: '/questionnaire/addQuestionnaire', query: { wenjuan_id: id, isView: true } });
		},
		showSendModal(item) {
			this.sendParam.wenjuan_id = item.wenjuan_id
			this.sendTitle = item.title
			this.sendShow = true
		},
		typeChange(e) {
			console.log(e)
			let val = e.target.value
			if (val == 1) {
				this.sendParam.customer_id = ''
				this.sendParam.file = ''
			}
			if (val == 2) {
				this.sendParam.file = ''
			}
			if (val == 3) {
				this.sendParam.customer_id = ''
			}
		},
		findUser(value) {
			console.log(value);
			let t = this;
			if (t.fetching) {
				return;
			}
			t.fetching = true;
			t.$get({
				port: 'a',
				url: 'customerlist',
				data: {
					searchInfo: value,
					page: 1,
					limit: 1000000
				}
			})
				.then(res => {
					t.fetching = false;
					let { code, data, msg } = res;
					if (code == 0) {
						t.seachUserData = data.list;
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {
					t.fetching = false;
				});
		},
		setUser(value) {
			console.log(value);
			this.sendParam.customer_id = value;
		},
		chooseFile() {
			console.log(23123)
			this.fileEle.click()
		},
		sendMsg() {
			let t = this
			if (t.sendParam.type == 2) {
				if (!t.sendParam.customer_id) {
					return t.$message.error('请选择用户', 1.5)
				}
			}
			if (t.sendParam.type == 3) {
				if (!t.sendParam.file) {
					return t.$message.error('请先选择文件', 1.5)
				}
			}

			var xhr = new XMLHttpRequest();
			xhr.open('post', 'https://jcapi.mtree.cn/api/admin/sendWenjuan');
			var formData = new FormData();
			formData.append('wenjuan_id', t.sendParam.wenjuan_id)
			formData.append('customer_id', t.sendParam.customer_id)
			formData.append('is_message', t.sendParam.is_message);
			formData.append('type', t.sendParam.type)
			formData.append('file', t.sendParam.file)
			formData.append('token', t.token)
			xhr.send(formData);
			xhr.onload = function (e) {
				var res = JSON.parse(xhr.response)
				console.log(res)
				let { data, code, msg } = res
				if (code == 0) {
					t.$message.success(msg, 1.5)
					t.page.current = 1
					t.getData()
					t.sendShow = false
				} else {
					t.$message.error(msg, 1.5)
				}
				console.log(data)
			}
			// console.log(formData)
			// t.$post({
			// 	port: 'a',
			// 	url: 'sendtongzhi',
			// 	data: formData
			// }).then(res => {
			// 	let { code, data, msg } = res
			// 	if (code == 0) {
			// 		t.$message.success(msg, 1.5)
			// 		t.$router.go(-1)
			// 	} else {
			// 		t.$message.error(msg, 1.5)
			// 	}
			// }).catch(err => {

			// })
		},
		downloadUserMb() {
			window.open('https://jcapi.mtree.cn/static/excel/sendToCustomer.xls')
		},
		copyWenjuan(wenjuan_id) {
			let t = this;
			t.$get({
				port: 'a',
				url: 'wenjuanCopy',
				data: { wenjuan_id: wenjuan_id }
			})
				.then(res => {
					let { code, data, msg } = res;
					if (code == 0) {
						t.$message.success(msg, 1.5);
						t.getData();
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => { });
		},
		deleteWenjuan(wenjuan_id) {
			let t = this;
			t.$get({
				port: 'a',
				url: 'wenjuanDel',
				data: { wenjuan_id: wenjuan_id }
			})
				.then(res => {
					let { code, data, msg } = res;
					if (code == 0) {
						t.$message.success(msg, 1.5);
						t.getData();
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => { });
		},
		showUserModal(type, wenjuan_id) {
			let t = this;
			t.userModalShow = true;
			t.userList = [];
			t.userParams = {
				type,
				wenjuan_id
			};
			t.userLoading = true;
			t.$get({
				port: 'a',
				url: 'typeCusList',
				data: t.userParams
			})
				.then(res => {
					t.userLoading = false;
					let { code, data, msg } = res;
					if (code == 0) {
						this.userList = data;
					} else {
						t.$message.error(msg, 1.5);
					}
				})
				.catch(err => {
					t.userLoading = false;
				});
		},
		exportExcel() {
			let t = this;
			console.log(pro_api);
			window.open(pro_api.a + 'typeCusExport?type=' + t.userParams.type + '&wenjuan_id=' + t.userParams.wenjuan_id);
			// t.$get({
			// 	port: 'a',
			// 	url: 'cusExport',
			// 	data: {}
			// }).then(res => {
			// 	let { code, data, msg } = res
			// 	if (code == 0) {
			// 		t.$message.success(msg, 1.5)
			// 	} else {
			// 		t.$message.error(msg, 1.5)
			// 	}
			// }).catch(err => {

			// })
		},
		userlistChange() { },
		downloadFenxi(id) {
			window.open(pro_api.a + 'wenjuanDownLoad?&wenjuan_id=' + id);
		},
		isDisabled(item) {
			let t = this
			let { is_use, wenjuan_id } = item

			t.$get({
				port: 'a',
				url: 'wenjuanUse',
				data: {
					is_use,
					wenjuan_id
				}
			}).then(res => {
				let { code, data, msg } = res
				if (code == 0) {
					t.$message.success(msg, 1.5)
					t.getData()
				} else {
					t.$message.error(msg, 1.5)
				}
			}).catch(err => {

			})
		}
	}
};
</script>

<style lang="less">

</style>
